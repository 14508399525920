<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-main opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{ trader.name }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <base-loader v-if="loading"></base-loader>
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      :src="`https://nqal.co/LiveNqal/public/uploads/traders/${trader.logo}`"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button
                  size="sm"
                  type="success"
                  class="mr-4"
                  v-if="trader.enable"
                  >{{ $t("traders.enable") }}</base-button
                >
                <base-button size="sm" type="danger" class="mr-4" v-else>{{
                  $t("traders.disable")
                }}</base-button>
                <base-button
                  size="sm"
                  type="danger"
                  class="float-right"
                  v-if="trader.busy"
                  >{{ $t("traders.busy") }}</base-button
                >
                <base-button
                  size="sm"
                  type="success"
                  class="float-right"
                  v-else
                  >{{ $t("traders.available") }}</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">{{ trader.trader_type_name }}</span>
                      <span class="description">{{
                        $t("traders.trader_type")
                      }}</span>
                    </div>
                    <div>
                      <span
                        class="heading badge badge-dot"
                        v-if="trader.allowMenu == 'allow'"
                      >
                        <i class="bg-success"></i> {{ $t("traders.yes") }}
                      </span>
                      <span class="heading badge badge-dot" v-else>
                        <i class="bg-danger"></i> {{ $t("traders.no") }}
                      </span>
                      <span class="description">{{
                        $t("traders.allow_menu")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ trader.name }}
                  <span class="font-weight-light">
                    , {{ "#" + trader.id }}
                  </span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ trader.email }}
                </div>
              </div>
              <hr />
              <div>
                <div class="form-group">
                  <label class="form-control-label">{{
                    $t("form.link_to_company")
                  }}</label>
                  <select
                    class="form-control form-control-alternative"
                    @change="linkToCompany($event)"
                  >
                    <option value="0" selected>
                      {{ $t("form.choose_company") }}
                    </option>
                    <option
                      v-for="(item, index) in companies"
                      :value="item.id"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ trader.name }} <small>({{ trader.sector }})</small>
                    </h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">
                      {{ $t("traders.info") }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <!-- Description -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("traders.description") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row mx-0">
                <p class="h5 font-weight-300">{{ trader.description }}</p>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Working Time -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("traders.working_time") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-6">
                  {{ $t("traders.from") }}: {{ trader.day_from }}
                </div>
                <div class="col-6">
                  {{ $t("traders.to") }}: {{ trader.day_to }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  {{ $t("traders.from") }}: {{ trader.time_from }}
                </div>
                <div class="col-6">
                  {{ $t("traders.to") }}: {{ trader.time_to }}
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Companies -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("traders.companies") }}
            </h6>
            <div class="pl-lg-4">
              <div class="table-responsive">
                <base-table
                  class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                  :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-dark'"
                  tbody-classes="list"
                  :data="trader.companies"
                >
                  <!-- Table Head -->
                  <template v-slot:columns>
                    <th>#</th>
                    <th>{{ $t("tables.name") }}</th>
                    <th>{{ $t("tables.email") }}</th>
                  </template>

                  <!-- Table Body -->
                  <template v-slot:default="row">
                    <td class="budget">
                      {{ row.item.id }}
                    </td>
                    <th scope="row">
                      <div class="media align-items-center">
                        <span class="avatar rounded-circle mr-3">
                          <img
                            :alt="row.item.name + 'Image'"
                            :src="row.item.logo_path"
                          />
                        </span>
                        <div class="media-body">
                          <router-link
                            :to="`/companies/${row.item.id}`"
                            class=""
                          >
                            <span class="name mb-0 text-sm">{{
                              row.item.name
                            }}</span>
                          </router-link>
                        </div>
                      </div>
                    </th>
                    <td class="budget">
                      {{ row.item.email }}
                    </td>
                  </template>
                </base-table>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Information -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("traders.information") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.orders_count") }}:
                  <span class="btn-success btn-sm">{{
                    trader.orders_cnt
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.couriers_count") }}:
                  <span class="btn-success btn-sm">{{
                    trader.couriers_cnt
                  }}</span>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Menu Settings -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("traders.menu_settings") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-12 mb-5">
                  <img
                    :src="`https://nqal.co/LiveNqal/public/uploads/traders/${trader.cover_page}`"
                    class="w-100"
                  />
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.allowMenu") }}:
                  <span
                    class="btn-success btn-sm"
                    v-if="trader.allowMenu == 'allow'"
                    >{{ $t("traders.yes") }}</span
                  >
                  <span class="btn-danger btn-sm" v-else>{{
                    $t("traders.no")
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.charge_fees") }}:
                  <span class="btn-success btn-sm">{{
                    trader.charge_fees
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.vat_included") }}:
                  <span class="btn-success btn-sm" v-if="trader.vat_included">{{
                    $t("traders.yes")
                  }}</span>
                  <span class="btn-danger btn-sm" v-else>{{
                    $t("traders.no")
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.have_shop_place") }}:
                  <span
                    class="btn-success btn-sm"
                    v-if="trader.have_shop_place"
                    >{{ $t("traders.yes") }}</span
                  >
                  <span class="btn-danger btn-sm" v-else>{{
                    $t("traders.no")
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.minimum_cart_value") }}:
                  <span class="btn-success btn-sm">{{
                    trader.minimum_cart_value
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.order_date_type") }}:
                  <span class="btn-success btn-sm">{{
                    trader.order_date_type
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.payment_type") }}:
                  <span class="btn-success btn-sm">{{
                    trader.payment_type
                  }}</span>
                </div>
                <div class="col-md-6 col-12 mb-4">
                  {{ $t("traders.receive_order_takeAway") }}:
                  <span
                    class="btn-success btn-sm"
                    v-if="trader.receive_order_takeAway"
                    >{{ $t("traders.yes") }}</span
                  >
                  <span class="btn-danger btn-sm" v-else>{{
                    $t("traders.no")
                  }}</span>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <h6 class="heading-small text-muted mb-4">
              {{ $t("form.all_transactions") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-12 mb-3">
                  {{ $t("traders.wallet") }}:
                  <span class="btn-success btn-sm">{{ trader.wallet }}</span>
                </div>
              </div>
              <!-- Errors Handling -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-control-label">{{
                      $t("form.type")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="transType"
                      @change="getTransByType($event)"
                    >
                      <option value="all" selected>
                        {{ $t("form.all") }}
                      </option>
                      <option value="charge">
                        {{ $t("form.charge") }}
                      </option>
                      <option value="withdrawal">
                        {{ $t("form.withdrawal") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div
                  class="card shadow"
                  :class="type === 'dark' ? 'bg-default' : 'bg-default'"
                >
                  <div
                    class="card-header border-0"
                    :class="type === 'dark' ? 'bg-default' : 'bg-default'"
                  >
                    <div class="row align-items-center">
                      <div class="col">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : 'text-white'"
                        >
                          {{ $t("tables.all_transactions") }}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive" v-if="transactions.length > 0">
                    <base-table
                      class="table align-items-center table-flush"
                      :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                      :thead-classes="
                        type === 'dark' ? 'thead-dark' : 'thead-dark'
                      "
                      tbody-classes="list"
                      :data="transactions"
                    >
                      <!-- Table Head -->
                      <template v-slot:columns>
                        <th>{{ $t("tables.total_transactions") }}</th>
                        <th>{{ $t("tables.total_amounts_charge") }}</th>
                        <th>{{ $t("tables.total_amounts_withdrawal") }}</th>
                        <th>{{ $t("tables.total_amounts_remain") }}</th>
                      </template>
                      <!-- Table Body -->
                      <template v-slot:default="row">
                        <td class="budget">
                          {{ row.item.total_transactons }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_charge }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_withdrawal }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_remain }}
                        </td>
                      </template>
                    </base-table>
                  </div>
                  <div class="alert alert-danger" v-else>
                    {{ $t("tables.no_trans") }}
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Charge Or Withdraw -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("form.charge_or_withdraw") }}
            </h6>
            <div class="pl-lg-4">
              <form @submit.prevent="submitForm">
                <!-- Errors Handling -->
                <div
                  class="row bg-danger py-3 mb-3"
                  v-if="validationErrors2.length"
                >
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors2"
                    :key="index"
                  >
                    <span class="text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="transActionType"
                      >
                        <option value="" selected>
                          {{ $t("form.choose") }}
                        </option>
                        <option value="charge">
                          {{ $t("form.charge") }}
                        </option>
                        <option value="withdrawal">
                          {{ $t("form.withdrawal") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.amount')"
                      :placeholder="$t('form.amount')"
                      input-classes="form-control-alternative"
                      v-model="amount"
                    />
                  </div>
                </div>
                <hr class="my-4" />
                <div class="col-lg-12 mt-4">
                  <button type="submit" class="btn btn-success d-block">
                    {{ $t("form.save") }}
                  </button>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "trader-profile",
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      validationErrors2: [],
      companies: [],
      trader: "",
      transType: "all",
      transactions: [],
      transActionType: "",
      amount: "",
    };
  },
  created() {
    this.getTrader();
    this.getCompanies();
    this.getTransByType("all");
  },
  methods: {
    getTrader: function () {
      this.loading = true;
      axios
        .get("admin/administration/traders/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.trader = response.data.data[0];
            console.log(this.trader);
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Change Transaction Status
    getTransByType: function ($type = null) {
      let transaction_type;
      if (event && event.target.value) {
        transaction_type = event.target.value;
      } else {
        transaction_type = $type;
      }
      this.loading = true;
      this.validationErrors = [];
      axios
        .post("admin/administration/wallets/modelWalletsTransactionTotal", {
          transaction_type: transaction_type,
          trader_id: this.$route.params.id,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.transactions = response.data.data;
            } else {
              this.validationErrors.push(response.data.message);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Charge Or Withdrawal
    validation: function () {
      this.validationErrors2 = [];
      if (this.transActionType && this.amount) {
        return true;
      } else {
        if (!this.transActionType) {
          this.validationErrors2.push(
            `${this.$t("validation.transaction required")}`
          );
        }
        if (!this.amount) {
          this.validationErrors2.push(
            `${this.$t("validation.amount required")}`
          );
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      this.chrg_withd();
    },
    // Charge Or Withdrawal
    chrg_withd: function () {
      this.loading = true;
      axios
        .post("admin/administration/wallets/store", {
          amount: this.amount,
          type: this.transActionType,
          trader_id: this.$route.params.id,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.getTransByType("all");
              if (this.transActionType == "charge") {
                this.trader.wallet = +this.trader.wallet + +this.amount;
              } else {
                this.trader.wallet = +this.trader.wallet - +this.amount;
              }
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.validationErrors2.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // All Companies
    getCompanies: function () {
      this.loading = true;
      axios
        .get("admin/administration/companies/index")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.companies = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    linkToCompany: function () {
      this.loading = true;
      axios
        .post("admin/administration/traders/linkcompany", {
          trader_id: this.trader.id,
          company_id: event.target.value,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.message,
                showConfirmButton: true,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          } else {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  height: 180px;
  width: 180px;
  object-fit: contain;
}
table img {
  height: 55px;
  width: 55px;
}
</style>
